import { gql } from "@apollo/client";

export const GET_MONTHLY_GOALS_DATA = gql`
  query Client($clientId: Int!, $dashboardParams: DashboardParams) {
    client(clientId: $clientId) {
      id
      name
      shops {
        active
        name
        id
      }
      dashboard(dashboardParams: $dashboardParams) {
        monthSalesProjection {
          goal {
            totalSales
          }
          salesProjection {
            monthSalesProjection
          }
          overviewReport {
            totalSales
          }
        }
        monthGPProjection {
          goal {
            overhead
            grossProfit
          }
          salesProjection {
            monthGrossProfitProjection
          }
          overviewReport {
            grossProfit
          }
        }
        monthCarCountProjection {
          goal {
            carCount
          }
          salesProjection {
            monthTicketCountProjection
          }
          overviewReport {
            totalTickets
          }
        }
        ytdSalesProjection {
          yearGoal {
            goal
          }
          salesProjection {
            yearSalesProjection
          }
          overviewReport {
            totalSales
          }
        }
      }
    }
  }
`;