import React from "react";
import { Button, Col, Row } from "react-bootstrap";

const NewVersion = ({ onReload }) => {
  return (
    <div
      className="container"
      style={{ backgroundColor: "white", borderRadius: "5px" }}
    >
      <Row>
        <Col md={12}>
          <div style={{ textAlign: "center" }}>
            <img
              src="/images/DC-Logo-420x400.png"
              width="250px"
              alt="OktoRocket Logo"
            />
            <p></p>
            <h1>A new version of OktoRocket has been released.</h1>
            <h3 className="text-muted">
              Click refresh to load the latest version. <br />
              If you continue to receive this message after, please contact{" "}
              <a href="mailto:support@bdmenterprises.com">
                support@bdmenterprises.com
              </a>
            </h3>
            <Button variant="success" size="large" onClick={onReload}>
              Refresh
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NewVersion;
