import React, { Component } from "react";
import { withFormik, Form, Field, ErrorMessage } from "formik";

import isEmpty from "../../validation/is-empty";
import { resetPassword } from "../../actions/auth";

import HeaderMessage from "../HeaderMessage";

export class ResetPassword extends Component {
  state = {
    alert: false,
    isLoading: false,
  };

  onResetPassword = () => {
    let domain = location.hostname.split(".")[0];
    this.setState({
      isLoading: true,
    });
    resetPassword(domain, this.props.values.email).then(() => {
      this.setState({
        isLoading: false,
        alert: true,
      });
    });
  };

  render() {
    const { errors, touched, values } = this.props;
    return (
      <div className="background" style={{ backgroundColor: "#f3f5f7" }}>
        <div className="container" style={{ backgroundColor: "#f3f5f7" }}>
          <div className="card shadow-lg mx-auto" style={{ maxWidth: "475px" }}>
            <div className="card-body p-4">
              <div className="text-center">
                <img
                  src="/images/OktoRocket-DC-VerticalLogo-RGB.svg"
                  width="300px"
                  alt="OktoRocket Logo"
                />
                <p></p>
              </div>
              {this.state.alert && (
                <HeaderMessage
                  type="success"
                  message="If that email address is valid, you will receive a password reset"
                />
              )}
              {!this.state.alert && (
                <h5 className="text-center mb-4">
                  Enter the email address associated with your account to
                  proceed with the password reset.
                </h5>
              )}
              {!this.state.alert && (
                <Form className="d-flex flex-column gap-2">
                  <div
                    className={`form-group ${
                      errors.email && touched.email ? " has-error" : ""
                    }`}
                  >
                    <Field
                      className="form-control"
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      required=""
                      autoFocus=""
                      autoCapitalize="none"
                      autoCorrect="off"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="help-block"
                    />
                  </div>
                  <button
                    className="btn btn-lg btn-outline-primary btn-block w-50 mx-auto"
                    onClick={this.onResetPassword}
                    disabled={isEmpty(values.email) || this.state.isLoading}
                  >
                    {this.state.isLoading ? "Resetting..." : "Reset Password"}
                  </button>
                  <div className="text-center my-3 small">
                    <a href="/">Back to Login</a>
                  </div>
                  <h5 className="text-center small">
                    © {new Date().getFullYear()}{" "}
                    <a href="https://oktorocket.com/">OktoRocket LLC</a>
                  </h5>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withFormik({
  mapPropsToValues: () => {
    return {
      email: "",
    };
  },
})(ResetPassword);
