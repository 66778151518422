module.exports = {
  dsn: process.env.DSN,
  stripePK: process.env.STRIPE_PK,
  stripePKv2: process.env.STRIPE_PK_V2,
  GA: process.env.GA,
  graphqlUrl: process.env.GRAPHQL_URL,
  cognitoAuthConfig: {
    authority: process.env.COGNITO_AUTHORITY,
    domain: process.env.COGNITO_DOMAIN,
    client_id: process.env.COGNITO_CLIENT_ID,
    redirect_uri: process.env.COGNITO_REDIRECT_URI,
    post_logout_redirect_uri: process.env.COGNITO_POST_LOGOUT_REDIRECT_URI,
    response_type: "code",
    scope: "email openid phone",
  },
};
