import { gql } from "@apollo/client";

export const CLAIM_COGNITO_USER = gql`
  mutation ClaimCognitoUser($input: ClaimCognitoUserInput!) {
    claimCognitoUser(input: $input) {
      cognitoUsername
      email
      isCognito
      isAdvancedAnalytics
      isCustomerExperience
      message
    }
  }
`;
