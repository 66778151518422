import React from "react";

const HeaderMessage = ({ type, message, onCloseError }) => {
  const alertClass = `alert alert-${type} d-flex align-items-center gap-3`;

  return (
    <div className={alertClass} role="alert">
      <button
        type="button"
        onClick={onCloseError}
        style={{
          color: "#ff4d4d",
          opacity: "1",
          textShadow: "none",
          fontSize: "1.75rem",
          fontWeight: "100",
          padding: "0.25rem",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          lineHeight: "1",
          transition: "all 0.2s ease",
        }}
        onMouseEnter={(e) => {
          e.target.style.color = "#cc0000";
          e.target.style.transform = "scale(1.1)";
        }}
        onMouseLeave={(e) => {
          e.target.style.color = "#ff4d4d";
          e.target.style.transform = "scale(1)";
        }}
        aria-label="Close"
      >
        ×
      </button>
      <span>{message}</span>
    </div>
  );
};

export default HeaderMessage;
