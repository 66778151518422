import { gql } from "@apollo/client";

export const LINK_OKTO_USER = gql`
  mutation Mutation($input: LinkOktoUserInput!) {
    linkOktoUser(input: $input) {
      id
      cognitoUsername
    }
  }
`;
