import React from "react";
import ReactDOM from "react-dom/client"; // Use `react-dom/client` for React 18
import { BrowserRouter } from "react-router-dom";
import packageJson from "../../../package.json";
global.appVersion = packageJson.version;

import App from "./App";

import "bootstrap/dist/css/bootstrap.css";
import "../../css/signin.css";
import { AuthProvider } from "react-oidc-context";
import siteConfig from "../siteConfig";

// Get the root element
const rootElement = document.getElementById("DC");

// Use createRoot instead of render
const root = ReactDOM.createRoot(rootElement);
root.render(
  <BrowserRouter>
    <AuthProvider {...siteConfig.cognitoAuthConfig}>
      <App />
    </AuthProvider>
  </BrowserRouter>
);
