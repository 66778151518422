import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const hostname = `https://${window.location.hostname.split(".")[0]}.api.digitalconcierge.io`;

const ShortLink = () => {
  const { code } = useParams();
  const [link, setLink] = useState(null);

  useEffect(() => {
    const fetchLink = async () => {
      try {
        const response = await fetch(`${hostname}/link/${code}`);
        const data = await response.text();
        setLink(data);
      } catch (error) {
        console.error("Error fetching the link:", error);
      }
    };
    fetchLink();
  }, [code]);

  useEffect(() => {
    if (link) {
      window.location.replace(link);
    }
  }, [link]);

  if (!link) {
    return <div>Loading Link</div>;
  }

  return null;
};

export default ShortLink;
