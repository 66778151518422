import React from "react";

function Reset() {
  localStorage.clear();
  sessionStorage.clear();
  window.location = "/";
  return <div>Clearing DC settings...</div>;
}

export default Reset;
