import axios from "axios";
import {
  GET_USER_ACCESS_MAP,
  CLAIM_COGNITO_USER,
  LINK_OKTO_USER,
  LINK_DC_USER,
} from "../../graphql/index";
import siteConfig from "../../../siteConfig";

export const authenticate = (domain, user, pass) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }

  return axios.post(`${baseUrl}/auth/authenticate`, {
    username: user,
    password: pass,
    domain: domain === "localhost" ? "dev" : domain,
  });
};

export const authenticateUsingCognito = (domain, token) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
    domain = "dev";
  }

  return axios.post(
    `${baseUrl}/auth/authenticateUsingCognito`,
    {
      domain,
    },
    {
      headers: { "X-Cognito-Token": token },
    }
  );
};

export const ssoUserMaps = (token) => {
  let baseUrl = siteConfig.graphqlUrl;

  return axios.post(
    `${baseUrl}`,
    {
      query: GET_USER_ACCESS_MAP.loc.source.body,
    },
    {
      headers: { "x-origin": "cognito", Authorization: `Bearer ${token}` },
    }
  );
};

export const linkOktoUser = (token, input) => {
  let baseUrl = siteConfig.graphqlUrl;

  return axios.post(
    `${baseUrl}`,
    {
      query: LINK_OKTO_USER.loc.source.body,
      variables: {
        input: { ...input },
      },
    },
    {
      headers: { "x-origin": "cognito", Authorization: `Bearer ${token}` },
    }
  );
};

export const linkDCUser = (token, input) => {
  let baseUrl = siteConfig.graphqlUrl;

  return axios.post(
    `${baseUrl}`,
    {
      query: LINK_DC_USER.loc.source.body,
      variables: {
        input: { ...input },
      },
    },
    {
      headers: { "x-origin": "cognito", Authorization: `Bearer ${token}` },
    }
  );
};

export const claimCognitoUser = (token, input) => {
  let baseUrl = siteConfig.graphqlUrl;
  console.log("Claiming Cognito User", input);
  return axios.post(
    `${baseUrl}`,
    {
      query: CLAIM_COGNITO_USER.loc.source.body,
      variables: {
        input: { ...input },
      },
    },
    {
      headers: {
        "x-origin": "digitalconcierge.io",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const getMyInfo = (domain, token) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }

  return axios.get(`${baseUrl}/users/myInfo`, {
    headers: { "x-access-token": token },
  });
};

export const resetPassword = (domain, email) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }

  return axios.post(`${baseUrl}/auth/forgot`, {
    email,
  });
};

export const checkResetToken = (domain, token) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }
  return axios.post(`${baseUrl}/auth/resetToken`, { token });
};

export const setPassword = (domain, params) => {
  let baseUrl = `https://${domain}.api.digitalconcierge.io`;

  if (domain === "localhost") {
    baseUrl = "http://localhost:3020";
  }
  return axios.post(`${baseUrl}/auth/resetPassword`, params);
};

export const isAllowed = (PID, path) => {
  switch (PID) {
    case "0":
    case "5":
      return { allowed: true };
    case "1":
      if (path.indexOf("userPortal") > -1 || path.indexOf("tech") > -1) {
        return { allowed: true };
      } else {
        return { allowed: false, redirect: "/userPortal" };
      }
    case "2":
      if (path.indexOf("tech")) {
        return { allowed: true };
      } else {
        return { allowed: false, redirect: "/tech" };
      }
    case "3":
      if (path.indexOf("userPortal")) {
        return { allowed: true };
      } else {
        return { allowed: false, redirect: "/userPortal/ro/board" };
      }
    default:
      return { allowed: false, redirect: "/" };
  }
};
