import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import Auth from "./components/auth/Auth";
import Reset from "./components/Reset";
import ResetPassword from "./components/auth/ResetPassword";
import SetPassword from "./components/auth/SetPassword";
import ShortLink from "./components/ShortLink";
import Configure from "../tech/components/ipad/Configure";
import CacheBuster from "./components/CacheBuster";
import NewVersion from "./components/NewVersion";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

class App extends Component {
  state = {
    initializing: false,
  };

  render() {
    console.log("Auth routes");
    return (
      <Sentry.ErrorBoundary showDialog>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }

            return (
              <>
                <ScrollToTop />
                <Routes>
                  <Route path="/admin" element={<Navigate to="/" />} />
                  <Route path="/" element={<Auth />} />
                  <Route path="/clear" element={<Reset />} />
                  <Route path="/setup" element={<Configure />} />
                  <Route path="/forgot" element={<ResetPassword />} />
                  <Route
                    path="/reset/:domain/:token"
                    element={<SetPassword />}
                  />
                  <Route path="/link/:code" element={<ShortLink />} />
                  <Route path="/refresh/test" element={<NewVersion />} />
                </Routes>
              </>
            );
          }}
        </CacheBuster>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
