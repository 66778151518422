import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik, FormikProvider, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import isEmpty from "../../validation/is-empty";
import { checkResetToken, setPassword } from "../../actions/auth";
import HeaderMessage from "../HeaderMessage";

const SetPassword = () => {
  const { domain, token } = useParams();
  const [isValid, setIsValid] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\D]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number"
        )
        .required("Password required"),
      confirm: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirmation required"),
    }),
    onSubmit: () => onSetPassword(),
  });

  useEffect(() => {
    if (!isEmpty(token) && !isEmpty(domain)) {
      checkResetToken(domain, token).then((resp) => {
        if (resp.data.success) {
          setIsValid(true);
          setIsLoading(false);
        } else {
          setIsValid(false);
          setMessage(resp.data.message);
          setIsLoading(false);
        }
      });
    }
  }, [domain, token]);

  const onSetPassword = () => {
    setIsLoading(true);
    setPassword(domain, {
      token,
      password: formik.values.password,
    }).then(() => {
      setAlert(true);
      setIsLoading(false);
    });
  };

  return (
    <div className="background" style={{ backgroundColor: "#f3f5f7" }}>
      <div className="container" style={{ backgroundColor: "#f3f5f7" }}>
        <div className="card shadow-lg mx-auto" style={{ maxWidth: "475px" }}>
          <div className="card-body p-4">
            <div className="text-center">
              <img
                src="/images/OktoRocket-DC-VerticalLogo-RGB.svg"
                width="300px"
                alt="OktoRocket Logo"
              />
              <p></p>
            </div>
            {alert && (
              <HeaderMessage
                type="success"
                message={
                  <>
                    Your password has been reset. Click <a href="/">here</a> to
                    sign in.
                  </>
                }
                onCloseError={() => setAlert(false)}
              />
            )}
            {!isValid && !isLoading && (
              <HeaderMessage type="danger" message={message} />
            )}
            {isValid && !alert && (
              <h5 className="text-center mb-4">Enter your new password.</h5>
            )}
            {isValid && !alert && (
              <FormikProvider value={formik}>
                <form
                  onSubmit={formik.handleSubmit}
                  className="d-flex flex-column gap-2"
                >
                  <div
                    className={`form-group ${
                      formik.touched.password && formik.errors.password
                        ? "has-error"
                        : ""
                    }`}
                  >
                    <Field
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="help-block"
                    />
                  </div>
                  <div
                    className={`form-group ${
                      formik.touched.confirm && formik.errors.confirm
                        ? "has-error"
                        : ""
                    }`}
                  >
                    <Field
                      className="form-control"
                      type="password"
                      name="confirm"
                      placeholder="Confirm"
                    />
                    <ErrorMessage
                      name="confirm"
                      component="div"
                      className="help-block"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-outline-primary btn-block w-50 mx-auto"
                    disabled={
                      isEmpty(formik.values.password) ||
                      isEmpty(formik.values.confirm) ||
                      !isEmpty(formik.errors) ||
                      isLoading
                    }
                  >
                    {isLoading ? "Setting Password..." : "Set Password"}
                  </button>
                  <div className="text-center my-3 small">
                    <a href="/">Back to Login</a>
                  </div>
                  <h5 className="text-center small">
                    © {new Date().getFullYear()}{" "}
                    <a href="https://oktorocket.com/">OktoRocket LLC</a>
                  </h5>
                </form>
              </FormikProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
