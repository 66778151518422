import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAuth } from "react-oidc-context";

import {
  authenticate,
  authenticateUsingCognito,
  claimCognitoUser,
  getMyInfo,
} from "../../actions/auth";
import isEmpty from "../../validation/is-empty";

import HeaderMessage from "../HeaderMessage";
import Input from "../forms/InputWithoutLabel";
import siteConfig from "../../../siteConfig";

import "../../../../css/auth.css";

const Auth = () => {
  const auth = useAuth();
  const [state, setState] = useState({
    isLoading: false,
    alert: false,
    message: "",
    domain: "",
    urlDomain: "",
  });

  useEffect(() => {
    console.log("attempt to refresh session");
    let domain = location.hostname.split(".")[0];

    const isAmplifyApp =
      /stage\.digitalconcierge\.io|amplifyapp\.com$|ngrok-free\.app|ngrok\.io$|ngrok\.app$/.test(
        location.hostname
      );

    if (domain !== "localhost" && domain !== "app" && !isAmplifyApp) {
      setState((prevState) => ({ ...prevState, domain: domain }));
    }

    const params = new URLSearchParams(window.location.search);
    const cognitoSession = params.get("cognitoSession") === "true";

    if (!cognitoSession) {
      console.log("no cognito session");
      refreshSession();
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const cognitoSession = params.get("cognitoSession") === "true";

    if (cognitoSession) {
      const cognitoToken = document.cookie.match(/cognitoToken=([^;]+)/)[1];
      const domain = document.cookie.match(/tenantDomain=([^;]+)/)[1];

      authenticateUsingCognito(domain, cognitoToken).then((resp) => {
        if (resp.data.success) {
          getMyInfo(domain, resp.data.token).then((user) => {
            setUser(user.data);
            setSession(resp.data, domain);
            onRouteUser(user.data);
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            alert: true,
            message: resp.data.message,
          }));
        }
      });
    }
  }, [auth.isAuthenticated]);

  const completeTenantAuth = () => {};

  const onCloseError = () => {
    setState((prevState) => ({
      ...prevState,
      alert: false,
      message: "",
    }));
  };

  const onLogin = async (values) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const authResp = await authenticate(
        values.domain,
        values.username,
        values.password
      );

      if (!authResp.data.success) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          alert: true,
          message: authResp.data.message,
        }));
        return;
      }
      const userInfo = await getMyInfo(values.domain, authResp.data.token);
      setUser(userInfo.data);
      setSession(authResp.data, values.domain);

      // if (!userInfo.data.cognitUsername) {
      //   const input = {
      //     firstName: userInfo.data.fname,
      //     lastName: userInfo.data.lname,
      //     username: values.username,
      //     email: userInfo.data.email,
      //     password: values.password,
      //     clientId: userInfo.data.clientId,
      //     source: "CE",
      //   };

      //   await claimCognitoUser(authResp.data.token, input);
      // }

      onRouteUser(userInfo.data);
    } catch (error) {
      console.log("onlogin error", error);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        alert: true,
        message: "Network Error, Check your internet connection and try again.",
      }));
    }
  };

  const handleSignInWithSSO = (auth) => {
    auth.signinRedirect();
  };

  const onImpersonateUser = (domain, token) => {
    getMyInfo(domain, token).then((user) => {
      setUser(user.data);
      onRouteUser(user.data);
    });
  };

  const onRouteUser = (user) => {
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get("redirect");
    let defaultRedirectUrl = determineUserLandingPage(user);
    window.location.href = redirectUrl
      ? decodeURIComponent(redirectUrl)
      : defaultRedirectUrl;
  };

  const determineUserLandingPage = (user) => {
    switch (user.PID) {
      case "0":
        return "/userPortal/admin";
      case "1":
        return "/userPortal";
      case "2":
        return "/tech";
      case "3":
        if (user?.preferences?.landingPage === "advisorBoard") {
          return "/userPortal/ro/board";
        } else if (user?.preferences?.landingPage === "techBoard") {
          return "/userPortal/ro/techBoard";
        } else {
          return "/userPortal/ro/board";
        }
      case "4":
        console.log("User type not authorized.");
        break;
      case "5":
        return "/userPortal/admin";
      default:
        console.log("user type unknown");
    }
  };

  const setUser = (user) => {
    localStorage.setItem("me", JSON.stringify(user));
  };

  const setSession = (authResult, domain) => {
    localStorage.setItem("token", authResult.token);
    localStorage.setItem("domain", domain);
    localStorage.setItem("refreshToken", authResult.refreshToken);
    localStorage.setItem("expires_at", authResult.exp);
  };

  const refreshSession = () => {
    if (isEmpty(localStorage.domain)) {
      localStorage.clear();
    } else if (!isEmpty(localStorage.impToken)) {
      console.log("impersonation token present");
      onImpersonateUser(localStorage.domain, localStorage.impToken);
    } else if (!isEmpty(localStorage.me) && !isEmpty(localStorage.expires_at)) {
      console.log("me and expires at in LS");
      if (new Date().getTime() / 1000 < localStorage.expires_at) {
        console.log("token has not expired");
        let me = JSON.parse(localStorage.me);
        onRouteUser(me);
      }
    } else {
      localStorage.clear();
    }
  };

  const signOutRedirect = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${siteConfig.cognitoAuthsiteConfig.domain}/logout?client_id=${siteConfig.cognitoAuthsiteConfig.client_id}&logout_uri=${encodeURIComponent(siteConfig.cognitoAuthsiteConfig.redirect_uri.replace("/site", ""))}`; //maybe we should just store this route as an env?
  };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="login-background">
      <div className="container login-container">
        <div className="announcement-banner">
          <strong>Exciting News:</strong> Digital Concierge and OktoRocket are
          joining forces! 🚀
          <br />
          <br /> Together, we're building a powerhouse platform to help shops
          like yours crush their goals.
          <br />
          <a href="https://oktorocket.com" target="_blank">
            Click Here
          </a>{" "}
          to learn more!
        </div>
        <div className="card mx-auto login-card">
          <div className="card-body p-4">
            <Formik
              enableReinitialize
              initialValues={{
                domain: state.domain,
                username: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                domain: Yup.string().required("Site Domain is required."),
                username: Yup.string().required("Username is required."),
                password: Yup.string().required("Password is required."),
              })}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ errors, touched, values }) => (
                <Form>
                  <div className="text-center">
                    <img
                      src="/images/OktoRocket-DC-VerticalLogo-RGB.svg"
                      className="login-logo"
                      alt="OktoRocket Logo"
                    />
                    <p></p>
                  </div>
                  {state.alert && (
                    <HeaderMessage
                      type="danger"
                      message={state.message}
                      onCloseError={onCloseError}
                    />
                  )}
                  <div className="d-flex flex-column gap-2">
                    {state.domain === "" && (
                      <Input
                        name="domain"
                        placeholder="Site Domain"
                        touched={touched}
                        errors={errors}
                        overload={{
                          autoFocus: !state.domain,
                          autoCapitalize: "none",
                          autoCorrect: "off",
                        }}
                      />
                    )}
                    <Input
                      name="username"
                      placeholder="User Name"
                      touched={touched}
                      errors={errors}
                      overload={{
                        autoFocus: state.domain,
                        autoCapitalize: "none",
                        autoCorrect: "off",
                      }}
                    />
                    <Input
                      name="password"
                      placeholder="Password"
                      touched={touched}
                      errors={errors}
                      overload={{
                        type: "password",
                        autoCapitalize: "none",
                        autoCorrect: "off",
                      }}
                    />
                    <button
                      className="btn btn-lg btn-outline-primary btn-block w-50 mx-auto"
                      onClick={() => onLogin(values)}
                      disabled={
                        isEmpty(values.password) ||
                        isEmpty(values.username) ||
                        state.isLoading
                      }
                    >
                      {state.isLoading ? "Authenticating..." : "Sign in"}
                    </button>
                  </div>
                  <div className="d-flex justify-content-center my-3">
                    <div className="text-center my-3 mx-2 small">
                      <a href="/forgot">Forgot Password</a>
                    </div>
                    {/*<div className="text-center my-3 small">
                      <a href="#" onClick={() => handleSignInWithSSO(auth)}>
                        Unified login (comming soon)
                      </a>
                    </div>*/}
                  </div>
                  <h5 className="text-center small">
                    © {new Date().getFullYear()}{" "}
                    <a href="https://oktorocket.com/">OktoRocket LLC</a>
                  </h5>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
