import { gql } from "@apollo/client";

export const GET_USER_ACCESS_MAP = gql`
  query Query {
    userAccessMap {
      _id
      cognitoUsername
      accessMap {
        displayName
        domain
        modelType
        url
      }
    }
  }
`;
